@import "../../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.FilterText {
  border-bottom: 1px solid currentColor;
  color: $hl-catalog-color-text;

  &:hover {
    color: $hl-catalog-color-hover;
    border-bottom-color: currentColor;

    ::placeholder {
      color: currentColor;
    }
  }

  .input-group-text,
  .form-control {
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  .input-group-text {
    padding: 0;
    color: currentColor;
  }

  .form-control {
    height: calc(#{$input-height} - 1px);
    font-size: inherit;
    line-height: inherit;
    padding-right: 0;

    &:focus {
      box-shadow: none;
    }
  }

  ::placeholder {
    color: $hl-catalog-color-text;
  }
}

.FilterText--is-focused {
  &,
  &:hover {
    border-bottom: 1px solid $hl-catalog-color-hover;

    ::placeholder {
      color: $hl-catalog-color-text;
    }
  }

  .input-group-text {
    color: $hl-catalog-color-hover;
  }
}
