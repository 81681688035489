@import "../../css/variables";

.hl-grouplist {

  .hl-grouplist__year {
    &:first-child {
      .hl-divider {
        margin-top: 0;
      }
    }
  }

  .hl-grid {
    display: grid;
    grid-gap: .8rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    // grid-auto-rows: minmax(15rem, 20rem);
    width: 100%;
  }

  .hl-divider {
    border-bottom: 1px solid currentColor;
    grid-column: 1/-1;
    margin-top: $spacer * 2;
    margin-bottom: $spacer;
  }
}