@import "../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

.FilterBar {
  // position: relative;
  margin-bottom: $spacer / 4;
  display: grid;
  grid-gap: $spacer;
  grid-template-areas: "fulltext sorting" "keywords keywords";
  grid-template-columns: 1fr 1fr;
  font-size: $font-size--1;
  line-height: $line-height-sm;

  @include media-breakpoint-up(lg) {
    grid-template-areas: "keywords fulltext sorting";
    grid-template-columns: minmax(12ch, 62%) minmax(12ch, 1fr) minmax(12ch, 17ch);
  }
}

.FilterBar__label {
  // color: $pharlap-contrast-AA;
  color: lighten(desaturate($hl-catalog-color-text, 20), 15);
  padding-bottom: $spacer / 8;
  border-bottom: 1px solid currentColor;
  margin-bottom: $spacer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  
  > * {
    // display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
      content: ":";
    }
  }
}

.FilterBar__keywords {
  grid-area: keywords;

  @include media-breakpoint-down(md) {
    margin-top: $spacer;
  }
}

.FilterBar__filters {
  display: grid;
  grid-gap: $spacer / 2;
  grid-template-columns: repeat(auto-fit, minmax(20ch, 1fr));

  @include media-breakpoint-down(sm) {
    row-gap: $spacer;
  }
}

// .FilterBar__filter {}

.FilterBar__fulltext {
  grid-area: fulltext;

  &.FilterBar__fulltext--fullWidth {
    grid-column: span 2;
  }
}
.FilterBar__sorting {
  grid-area: sorting;
}
