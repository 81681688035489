@import "../css/variables";

.helpdesk-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: $spacer;

  > div {
    /*background-color: #976463;*/
    width: $spacer * 15;
    margin: 0 $spacer 0 $spacer;
    margin-bottom: $spacer * 0.5;
  }
}

.showPictures {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > li {
    margin: 10px;
    position: relative;

    button {
      position: absolute;
      top: 1px;
      right: 1px;
      color: gray;
      background-color: transparent;
      border-width: 0;
      text-shadow: 2px 2px 8px lightgray;
    }

    button:hover {
      text-shadow: 2px 2px 8px black;
    }
  }
}

.dropzone li {
  background-color: var(--light);
  border: 1px solid #ced4da;
  border-radius: $spacer / 3;
  padding: 2px;
}

.normal {
  background-color: $secondary;
}

.orange {
  background-color: $orange;
}

.significant {
  background-color: $danger;
}

@media only screen and (min-width: 1490px) {
  .container {
    max-width: 1000px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px !important;
  }
}
