@import "../../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

// same as SortOrderButton
// TODO:DRY: abstract the code

.FilterSelect {
  position: relative;

  .FilterSelect__control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid currentColor;
    color: $hl-catalog-color-text;
    cursor: pointer;
    // min-height: initial;
    min-height: $input-height;

    &:hover {
      color: $hl-catalog-color-hover;
      border-bottom-color: currentColor;

      .FilterSelect__placeholder {
        color: inherit;
      }

      .FilterSelect__dropdown-indicator {
        background-color: $hl-catalog-color-text;
        color: $hl-catalog-tag-color;
      }

      .FilterSelect__clear-indicator {
        display: block;
      }
    }
  }

  .FilterSelect__control--is-focused {
    border: 0;
    border-bottom: 1px solid $hl-catalog-color-hover;
    box-shadow: none;

    &,
    &:hover {
      .FilterSelect__placeholder {
        color: $hl-catalog-color-hover;
      }
    }

    &:hover {
      border-bottom-color: currentColor;
    }

    .FilterSelect__dropdown-indicator {
      background-color: $hl-catalog-color-text;
      color: $hl-catalog-tag-color;
    }
  }

  // .FilterSelect__control--menu-is-open {}

  .FilterSelect__value-container {
    order: 2;
    padding: $spacer / 8 0;
    padding-left: $spacer / 8;
  }
  
  // .FilterSelect__value-container--is-multi {}
  // .FilterSelect__value-container--has-value {}
  .FilterSelect__placeholder {
    color: currentColor;
  }

  // .FilterSelect__multi-value,
  .FilterSelect__single-value {
    cursor: pointer;
    padding: $spacer / 8 $spacer / 3;
    height: 100%;

    border-radius: $spacer;
    background-color: $hl-catalog-tag-bgColor;
    color: $hl-catalog-tag-color;
  }

  // .FilterSelect__input {}
  // .FilterSelect__indicators {}

  .FilterSelect__indicator {
    border-radius: $spacer;
    color: currentColor;
    cursor: pointer;
    padding: 0;
  }

  .FilterSelect__indicator-separator {
    display: none;
  }

  // .FilterSelect__dropdown-indicator {}
  
  .FilterSelect__clear-indicator {
    display: none;
    z-index: 1;
    position: absolute;
    right: 0;
    order: 2;
    background-color: $hl-catalog-color-bg;
    // border: 1px solid currentColor;
    box-shadow: 0 0 $spacer / 2 $spacer / 10 $hl-catalog-color-bg;
    color: $hl-catalog-color-text;

    &:hover {
      color: $hl-catalog-color-hover;
    }
  }

  .FilterSelect__menu {
    border-radius: $border-radius;
  }

  // .FilterSelect__menu-list {}
  // .FilterSelect__menu-list--is-multi {}
  .FilterSelect__option {
    cursor: pointer;
    
    &--is-focused,
    &:hover {
      color: $light;
    }
  }
  // .FilterSelect__option--is-focused {}
}

.FilterSelect__placeholderIfHasValue {
  position: absolute;
  top: 0;
  left: 0;
  // top: 50%;
  // transform: translateY(-50%);
  // bottom: 0;
  // padding: $spacer / 8 0;
  margin-bottom: 0;
  z-index: -1;
  opacity: 0;
  transition: transform 0.1s, opacity 0.08s;
  color: $hl-catalog-color-text;

  &--has-value {
    opacity: 1;
    transform: translateY(-100%);
    transition: transform 0.1s, opacity 0.05s 0.05s;
  }
}
