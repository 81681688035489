.language-switcher-menu {
  margin-top: 0.3rem;
  padding: 0.3rem;
  color: currentColor;
  min-width: auto;
}

.language-switcher-item {
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;


  &:hover {
    background: none;
  }
}
