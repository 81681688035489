@import "css/variables";

.ExerciseList {
  // same as GroupList.scss
  .hl-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(#{$spacer * 10}, 1fr));
    grid-auto-rows: $spacer * 12;
    grid-gap: $spacer / 2;
    padding: $spacer 0;
  }

  .hl-divider {
    border-bottom: 1px solid currentColor;
    grid-column: 1/-1;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
  }
}