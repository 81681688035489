@import "../../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

// same as SortOrderButton
// TODO:DRY: abstract the code

.SortOrderButton {
  position: relative;

  .SortOrderButton__control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid currentColor;
    color: $hl-catalog-color-text;
    cursor: pointer;
    // min-height: initial;
    min-height: $input-height;

    &:hover {
      color: $hl-catalog-color-hover;
      border-bottom-color: currentColor;

      .SortOrderButton__placeholder {
        color: inherit;
      }

      .SortOrderButton__dropdown-indicator {
        background-color: $hl-catalog-color-text;
        color: $hl-catalog-tag-color;
      }
    }
  }

  .SortOrderButton__control--is-focused {
    border: 0;
    border-bottom: 1px solid currentColor;
    box-shadow: none;
    color: currentColor;

    &,
    &:hover {
      .SortOrderButton__placeholder {
        color: currentColor;
      }
    }

    &:hover {
      border-bottom-color: currentColor;
    }

    .SortOrderButton__dropdown-indicator {
      background-color: $hl-catalog-color-text;
      color: $hl-catalog-tag-color;
    }
  }

  // .SortOrderButton__control--menu-is-open {}

  .SortOrderButton__value-container {
    order: 2;
    padding: $spacer / 8 0;
    padding-left: $spacer / 8;
  }
  
  // .SortOrderButton__value-container--is-multi {}
  // .SortOrderButton__value-container--has-value {}
  .SortOrderButton__placeholder {
    color: currentColor;
  }

  // .SortOrderButton__multi-value,
  .SortOrderButton__single-value {
    cursor: pointer;
    padding: $spacer / 8 0;
    height: 100%;

    background-color: transparent;
    color: currentColor;
  }

  // .SortOrderButton__input {}
  // .SortOrderButton__indicators {}

  .SortOrderButton__indicator {
    border-radius: $spacer;
    color: currentColor;
    cursor: pointer;
    padding: 0;
  }

  .SortOrderButton__indicator-separator {
    display: none;
  }
  // .SortOrderButton__clear-indicator {}
  // .SortOrderButton__dropdown-indicator {}

  .SortOrderButton__menu {
    border-radius: $border-radius;
  }

  // .SortOrderButton__menu-list {}
  // .SortOrderButton__menu-list--is-multi {}
  .SortOrderButton__option {
    cursor: pointer;
    
    &--is-focused,
    &:hover {
      color: $light;
    }
  }
  // .SortOrderButton__option--is-focused {}
}