@font-face {
  font-family: "Lato";
  src: url("LatoLatin-Regular.woff2") format("woff2"), url("LatoLatin-Regular.woff") format("woff"), url("LatoLatin-Regular.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Lato";
  src: url("LatoLatin-Bold.woff2") format("woff2"), url("LatoLatin-Bold.woff") format("woff"), url("LatoLatin-Bold.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
