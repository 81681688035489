@import "./css/variables";

.EnrolmentButtons {
  display: grid;
  grid-template-areas:
    "heading . ."
    "buttons or try";
  column-gap: $spacer;
  row-gap: 0.2rem;
  align-items: center;

  .btn svg {
    margin-right: 0.62ch;
  }
}

.EnrolmentButtons__heading {
  grid-area: heading;
  border-bottom: 1px solid rgba($light, 0.25);
  color: rgba($light, 0.8);
  margin-bottom: $spacer / 4;
  padding-bottom: $spacer / 4;

  > * {
    color: currentColor;
  }

  h5 {
    font-size: $font-size--1;
  }
}

.EnrolmentButtons__buttons {
  grid-area: buttons;
}

.EnrolmentButtons__or {
  grid-area: or;
  text-align: center;
}

.EnrolmentButtons__try {
  grid-area: try;
}

.CatalogExerciseDetail {
  .EnrolmentButtons {
    background-color: rgba($dark, 0.62);
    border-radius: $spacer / 2;
    box-shadow: 0 0 $spacer rgba($dark, 0.62);
    padding: $spacer;
    opacity: 0.8;
  }
  .EnrolmentButtons__heading {
    text-align: left;
  }
}

.TimelineExerciseDetail {
  .EnrolmentButtons__heading {
    border-bottom: 1px solid rgba($light, 0.125);
    color: rgba($light, 0.62);
  }
}

@media screen and (max-width: 920px) {
  .EnrolmentButtons {
    grid-template-areas:
      "heading"
      "buttons"
      "or"
      "try";
    row-gap: 0.4rem;
  }
}

@media screen and (max-width: 600px) {
  .EnrolmentButtons__buttons {
    .btn {
      font-size: 1rem;
    }
  }
  .EnrolmentButtons__try {
    .btn {
      font-size: 1rem;
    }
  }
}
