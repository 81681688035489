h1, h2,
.h1, .h2,
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2,
.h1, .h2 {
  letter-spacing: -0.02em;
}

h1, 
.h1 {
  margin-bottom: $spacer * 2;
}
h2, 
.h2 {
  margin-bottom: $spacer;
}


h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: $font-weight-base;
}

p {
  margin-bottom: $spacer;

  &:last-child {
    margin-bottom: 0;
  }
}