@import "../../../css/variables";

.ExerciseDetail {
  .menu--vertical {

    .btn,
    .nav-link {
      text-align: left;
    }
  }
}