@import "./css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$detail-width: 90ch;

.CatalogExerciseDetail {
  position: relative;
  margin: 0;
  background-color: $dark;

  > .container-fluid {
    color: $light;
    // min-height: 100vh;
  }

  header.has-alert--1 ~ & {
    padding-top: $spacer * 3;
  }

  header.has-alert--2 ~ & {
    padding-top: $spacer * 6;
  }

  header.has-alert--3 ~ & {
    padding-top: $spacer * 9;
  }
}

.CatalogExerciseDetail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.25;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(10px);
  }

  ~ * {
    position: relative;
  }
}

.CatalogExerciseDetail__body {
  height: 100vh;
  min-height: $spacer * 27;
  margin-top: $spacer * -3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > :not(:last-child) {
    margin-bottom: $spacer;
  }
}

.CatalogExerciseDetail__name {
  text-shadow: 0 0 0.38em rgba($dark, 0.62);

  h1 {
    font-size: $display4-size;
    margin-bottom: $spacer * 0.5;
    max-width: $detail-width / 2;
  }
}

.CatalogExerciseDetail__meta {
  display: grid;
  grid-template-columns: minmax(15ch, auto) 1fr minmax(10ch, auto) minmax(
      13ch,
      auto
    );
  grid-template-areas:
    "difficulty keywords keywords keywords"
    "time authors mobileFriendly guide";
  color: rgba($light, 0.8);
  width: 100%;
  max-width: $detail-width;

  > * {
    border-bottom: 1px solid rgba($light, 0.25);
    display: flex;
    align-items: flex-end;
    font-size: $font-size--1;
    line-height: $headings-line-height;
    padding: $spacer $spacer / 4 $spacer / 4;
    position: relative;

    &::before {
      content: attr(data-caption);
      position: absolute;
      top: $spacer / 4;
      left: $spacer / 4.5;
      font-size: $font-size--3;
      letter-spacing: 0.05em;
      opacity: 0.8;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  a {
    color: $mahogany;
  }
}

// .CatalogExerciseDetail__author,
// .CatalogExerciseDetail__difficulty,
// .CatalogExerciseDetail__epochs,
// .CatalogExerciseDetail__years,
// .CatalogExerciseDetail__keywords {}

.CatalogExerciseDetail__difficulty,
.CatalogExerciseDetail__authors,
.CatalogExerciseDetail__time,
.CatalogExerciseDetail__mobileFriendly {
  border-right: 1px solid rgba($light, 0.25);
  // border-right: 1px solid rgba($light, 1);
}

.CatalogExerciseDetail__guide {
  grid-area: guide;

  :not(:first-child) {
    margin-left: 0.75ch;
  }
}
.CatalogExerciseDetail__time {
  grid-area: time;
}
.CatalogExerciseDetail__difficulty {
  grid-area: difficulty;
}
// .CatalogExerciseDetail__epochs {
//   grid-area: epochs;

//   .badge--history:not(:last-child)::after {
//     content: ", ";
//   }
// }
// .CatalogExerciseDetail__years {
//   grid-area: years;
// }
.CatalogExerciseDetail__mobileFriendly {
  grid-area: mobileFriendly;
  padding-left: $spacer * 1.1;

  svg {
    width: 100%;
    height: 100%;
    width: $spacer * 1.25;
    height: $spacer * 1.25;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-5%, -25%);
  }
}
.CatalogExerciseDetail__authors {
  grid-area: authors;
}
.CatalogExerciseDetail__keywords {
  grid-area: keywords;

  .keyword:not(:first-child) {
    margin-left: 0.62ch;
  }
}

.CatalogExerciseDetail__description,
.CatalogExerciseDetail__logos {
  max-width: $detail-width;
  margin-bottom: $spacer;
  text-align: left;
}

.CatalogExerciseDetail__logos {
  width: 100%;
}

.CatalogExerciseDetail__buttons {
  // grid-area: buttons;
  position: sticky;
  bottom: $spacer;
  max-width: $detail-width;
  margin: 0 auto $spacer;
}


@media screen and (max-width: 600px) {
  .CatalogExerciseDetail__meta {
    grid-template-areas:
      "difficulty time time mobileFriendly"
      "keywords keywords keywords keywords"
      "authors authors guide guide";
  }
  .CatalogExerciseDetail__mobileFriendly {
    border-right: unset;
  }
}