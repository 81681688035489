@import "../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$card-spacer: $spacer / 2;

.card {
  border: 0;
  border-radius: $spacer / 3;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  padding: $card-spacer;
  position: relative;

  transition: color $hl-transition-duration-short ease,
    transform $hl-transition-duration-short ease,
    box-shadow $hl-transition-duration-short ease;
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 0 $spacer / 3 $card-spacer rgba($dark, 0.62);
    transform: translate3d(0, -#{$spacer / 3}, 0);
  }
}

.card--activity {
  background-color: $dark;
  color: #fce4e3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    color: $light;
  }
}

.card__image {
  opacity: 0.62;
  z-index: -1;

  &,
  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &::before {
    content: "";
    background-color: rgba($dark, 0.38);
    opacity: 0;
    transition: opacity $hl-transition-duration-short ease;
    z-index: 1;
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba($dark, 0.38) 0, rgba($dark, 0.9));
    z-index: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .card:hover & {
    &::before {
      opacity: 1;
    }
  }
}

.card__header {
  position: absolute;
  top: $card-spacer;
  left: $card-spacer;
  right: $card-spacer;
  z-index: 1;

  display: grid;
  // grid-template-areas: "left middleLeft middleRight right";
  // grid-template-areas: "left middleLeft middleRight right";
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto 1fr;
  line-height: 0;
}

.card__histories {
  grid-column: 3 / 5;
  grid-row: 1;
  transform: translateX(#{$spacer * 0.75 / 2});
  transition: transform $hl-transition-duration-short;
  will-change: transform;
  z-index: -1;

  .badge--history {
    position: relative;
    display: inline-block;
    width: $spacer * 0.75;
    height: $spacer * 0.75;
    border-radius: $spacer * 0.75;

    &:not(:last-child) {
      transform: translateX(50%);
      transition: transform $hl-transition-duration-short;
      will-change: transform;
    }
  }

  .card:hover & {
    &,
    .badge--history {
      transform: none !important;
    }
  }
}

.card__years {
  grid-column: 4;

  .badge--year {
    padding: 0 $spacer / 3;
    line-height: $spacer * 0.75;
    border-radius: $spacer * 0.75;
  }
}

.card__difficulty {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  width: $spacer * 0.75;
  height: $spacer * 0.75;
  border-radius: $spacer * 0.75;
  background-color: hsl(96, 33%, 60%);

  &:empty {
    display: none;
  }

  .card:hover & {
    span {
      opacity: 1;
      transform: translate3d(0, -50%, 0);
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 100%;
    padding-left: $spacer / 4;

    color: hsl(96, 33%, 80%);
    font-size: $font-size--3;
    letter-spacing: 0.05em;
    line-height: $headings-line-height;
    text-shadow: 0 0 $card-spacer rgba($dark, 0.8), 0 0 $spacer rgba($dark, 0.8);
    text-transform: uppercase;
    white-space: nowrap;

    opacity: 0;
    transform: translate3d(-#{$card-spacer}, -50%, 0) scale(0.9);
    transition: opacity $hl-transition-duration-short,
      transform $hl-transition-duration-short;
  }
}

.card__time {
  grid-column: 1;
  grid-row: 1;
  font-size: $font-size--2;
  line-height: $headings-line-height;
  color: hsl(96, 33%, 80%);
  opacity: 0;
  text-shadow: 0 0 $card-spacer rgba($dark, 0.8), 0 0 $spacer rgba($dark, 0.8);
  transform: translate3d(-#{$card-spacer}, 0, 0);
  transition: opacity $hl-transition-duration-short,
    transform $hl-transition-duration-short;

  .card__difficulty:not(:empty) + & {
    margin-top: $spacer * 0.75 + $spacer * 0.25;
  }

  .card:hover & {
    opacity: 1;
    transform: none;
  }
}

.card__workType {
  grid-column: 4;
  grid-row: 1;

  .badge {
    font-weight: $font-weight-normal;
  }
}

.card__keywords {
  grid-column: 3;
  grid-row: 1 / 3;
  text-align: right;
  opacity: 0;
  transition: opacity $hl-transition-duration-short;
  will-change: opacity;

  // font-size: $font-size--1;
  // line-height: 1.4;
  // margin-bottom: $spacer / 3;
  // opacity: .62;

  // .keyword:not(:first-child) {
  //   margin-left: .62ch;
  // }

  .badge--keyword {
    &:not(:first-child) {
      transition: transform $hl-transition-duration-short;
      will-change: transform;
      margin-top: $spacer / 4;
    }

    .badge {
      background: $dark;
      box-shadow: 0 0 $spacer / 4 rgba($dark, 0.38);
      color: $dark;
      font-weight: $font-weight-normal;
      transition: background $hl-transition-duration-short;
      will-change: background;
    }
  }

  .card:hover & {
    opacity: 1;

    .badge--keyword {
      transform: none !important;
    }

    .badge {
      background: #fce4e3;
    }
  }
}

.card__mobileFriendly {
  grid-column: 1;
  grid-row: 2;
  width: $spacer * 1.5;
  height: $spacer * 1.5;
  margin-left: -23%;

  transition: opacity $hl-transition-duration-short,
    transform $hl-transition-duration-short;

  &:not(.card__mobileFriendly--visible) {
    opacity: 0;
    transform: translate3d(-#{$card-spacer}, 0, 0);
  }

  svg {
    width: 100%;
    height: 100%;
  }
  .card:hover & {
    opacity: 1;
    transform: none;
  }
}

.card__title {
  text-shadow: 0 1px 0.38em rgba($dark, 0.5);

  &:hover {
    color: lighten($light, 5);
  }

  &:not(:last-child) {
    margin-bottom: $card-spacer;
  }

  > * {
    font-weight: $font-weight-bold;
  }
}

.card__description {
  font-size: $font-size--1;
  line-height: $line-height-sm;
  opacity: 0.8;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.card__progress {
  .card__progressText {
    text-align: center;
    font-size: $font-size--1;
  }
}

// .card--catalog {
// }

// .card--timeline {
// }

.card--exerciseList {
  .card__title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: $spacer;
  }
}
.card-locked {
  border: 20px #cecece solid;
  box-shadow: inset 0px 0px 159px 67px #faf5f5;
}

.card--visualCloud {
  justify-content: center;

  .card__image {
    &::after {
      opacity: 0;
    }
  }

  .card__title {
    opacity: 0;
    text-align: center;
  }

  &:hover {
    .card__image {
      &::after {
        opacity: 1;
      }
    }

    .card__title {
      opacity: 1;
    }
  }
}
