@import "../css/variables";

.support__item {
  width: 100%;
  max-width: 90ch;
  margin: 0 auto $spacer * 3;

  &:not(:last-of-type) {
    border-bottom: 1px solid $pharlap;
    padding-bottom: $spacer * 3;
  }
}

.support__media--video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
