@import "../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$universal: 62vmin;
$unit: 1vmin;

.exercises {
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(#{$spacer * 10}, 1fr));
  grid-auto-rows: $unit * 30;
  grid-gap: 3px;
  grid-gap: $unit;
  opacity: 1;
  overflow-y: auto;
  padding: $unit * 2;
  padding-left: $unit;
  transform: none;
  transform-origin: 38% center;
  will-change: opacity, transform;
  transition: all $hl-transition-duration-medium;

  &.unscroll {
    overflow-y: hidden;
    opacity: .62;
    transform: scale3d(.9,.9,1);
  }

  .is-detail &,
  .is-detail ~ & {
    overscroll-behavior: contain;
  }
}
