.ChooseOrCreateGroupModal {
  [class^="col-"] {
    text-align: center;
  }

  h3 + * {
    flex: 1;
  }

  .col-divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-size: small;
    opacity: 0.38;
  }

  button[type="submit"] {
    i {
      display: inline;
      width: auto;
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: inherit;
      white-space: nowrap;
    }
  }
}
