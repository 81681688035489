@import "css/variables";

$help-size: $spacer * 1.5;

.Help {
  position: fixed;
  z-index: 9999998;
  bottom: $spacer;
  right: $spacer;
  color: $light;
}

.Help__icon,
.Help__item {
  width: $help-size;
  height: $help-size;

  .btn {
    cursor: pointer;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    background-color: $success;
    border-radius: $help-size;
    border: 0;
    box-shadow: 0 0 $spacer rgba($dark, 0.2);
    overflow: hidden;

    svg {
      width: $help-size / 2;
      height: $help-size / 2;
    }
  }
}

.Help__icon {
  position: relative;
  z-index: 1;

  .svg-inline--fa {
    opacity: 0.62;
  }

  .Help:hover & {
    .svg-inline--fa {
      opacity: 1;
    }
  }
}

.Help__items {
  position: absolute;
  bottom: 0;
  right: 100%;
  opacity: 0;
  transform: translateX(#{$help-size});
  will-change: opacity, transform;
  transition: opacity $hl-transition-duration-short,
    transform $hl-transition-duration-short;
  z-index: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: $help-size / 4;

  .Help:hover & {
    transform: none;
    opacity: 1;
  }
}

.Help__item {
  position: relative;

  &:not(:last-child) {
    margin-left: $help-size / 6;
  }
}

.Help__label {
  position: absolute;
  bottom: calc(100% + #{$spacer / 4});
  left: 50%;
  transform: translateX(-50%);
  display: none;
  font-size: $font-size--1;
  line-height: $line-height-sm;
  text-align: center;
  white-space: nowrap;
  padding: $spacer / 5 $spacer / 3;
  background-color: rgba($dark, 0.38);
  border-radius: 3px;

  .Help__item:hover & {
    display: block;
  }
}
