@import "../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.Logos {
  display: flex;
  flex-wrap: wrap;
}

.Logos__heading {
  width: 100%;
  
  color: rgba($light, 0.8);
  font-size: $font-size--1;
  margin-bottom: $spacer / 4;
  text-align: left;
}

.Logos__item {
  fill: currentColor;
  height: $spacer * .75;

  &:not(:first-of-type) {
    margin-left: $spacer;
  }

  svg {
    height: 100%;
    width: auto;
  }
}