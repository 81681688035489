@import "../css/variables";

input[type="radio"] {
  margin-right: $spacer / 2;
  margin-left: $spacer / 2;
}

.bugType-radio__title {
  margin-bottom: 0.5rem;
}
