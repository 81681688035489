.groupSelector {
  &--scroll {
    height: 220px;
    overflow-y: auto;  
    overflow-x: hidden;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3rem;
      background: linear-gradient(to top, rgba(white, 1) 0%, rgba(white, 0) 100%);
      pointer-events: none;
      z-index: 1;
    }
  }
}

.groupSelector__list {
  width: 100%;
  margin-bottom: 1rem;
}

.groupSelector__item {
  background-color: var(--light);
  text-align: left;

  > :not(:first-child) {
    margin-left: 1ch;
  }
}

.groupSelector__item--selected {
  background-color: var(--success);
  color: var(--light);
}

.groupSelector__year {
  font-size: small;
  opacity: 0.38;
}
