@import "./css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$logo-size: $spacer * 1.25;

.header {
  background-color: lighten($light, 2);
  color: var(--primary);
  padding: 0 $spacer / 2;

  &.is--hidden {
    display: none;
  }

  &.is-inverted {
    background-color: transparent;
    color: var(--light);

    .logo {
      color: currentColor;
    }

    .MainHeader__testServer {
      color: lighten($pharlap-contrast-AA, 10);
    }

    .MainNavigation {
      a,
      button {
        text-shadow: 0 0 $spacer var(--dark);
      }
    }

    .MainNavigation__item {
      &:not(:last-child) {
        border-bottom: 1px solid rgba($light, 0.125);
      }
    }

    .MainNavigation__secondary {
      color: lighten($pharlap-contrast-AA, 10);

      a,
      button {
        &:hover {
          color: lighten($pharlap-contrast-AA, 10);
          text-decoration: underline;
        }
      }
    }

    &.header--dark {
      color: var(--dark);

      .MainNavigation {
        a,
        button {
          text-shadow: 0 0 $spacer var(--light);
        }
      }

      .MainNavigation__secondary {
        color: currentColor;
  
        a,
        button {
          &:hover {
            color: currentColor;
            text-decoration: underline;
          }
        }
      }

      .MainNavigation__item {
        &:not(:last-child) {
          border-bottom-color: rgba($dark, 0.125);
        }
      }
    }
  }

  &.is-absolutePositioned {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    z-index: 1;

    .alert + & {
      top: $spacer * 2;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    color: $dark;

    &,
    &:hover {
      border-bottom: 0;
    }

    svg {
      margin-top: $logo-size / 10;
      height: $logo-size;
      width: $logo-size * 5.18644375; // 8.29831 / 1.6 = 5.18644375
    }
  }

  a,
  button {
    display: block;
    height: 100%;
    max-height: 100%;
    color: currentColor;
    // border-bottom: 1px solid transparent;
    // text-decoration: none;
    // transition: color 0.1s cubic-bezier(0.62, 0.05, 0.38, 1),
    //   border-bottom-color 0.1s cubic-bezier(0.62, 0.05, 0.38, 1);
    line-height: $line-height-sm;

    &:hover {
      //   border-bottom: 1px solid currentColor;
      // color: $primary;
      text-decoration: none;
    }
  }

  button {
    appearance: none;
    border-radius: 0;
    padding: 0;
  }

  svg {
    fill: currentColor;
  }

  .MainNavigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .btn {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .MainNavigation__item {
    display: flex;
    align-items: center;
    // justify-content: center;

    &:not(:last-child) {
      // padding-bottom: $spacer / 10;
      border-bottom: 1px solid rgba($dark, 0.125);
      margin-bottom: $spacer / 4;
    }
  }

  .MainNavigation__primary {
    flex: 1;
    color: currentColor;
    font-weight: $font-weight-bold;
    margin-right: $nav-link-padding-x * -1;

    .nav-item {
      > * {
        padding: $nav-link-padding-y $nav-link-padding-x;
      }
    }

    a,
    button {
      &:hover {
        color: $mahogany;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }

  .MainNavigation__secondary {
    // order: 2;
    color: $pharlap-contrast-AA;
    font-size: $font-size--1;
    font-weight: $font-weight-normal;
    margin-right: $nav-link-padding-x / 2 * -1;

    .nav-item {
      > * {
        display: inline-block;
        vertical-align: text-bottom;
        padding: 0 $nav-link-padding-x / 1.5;
      }
    }

    a,
    button {
      color: currentColor;

      &,
      &:hover {
        border-bottom: 0;
      }

      &:hover {
        color: $dark;
      }
    }
  }
}

.MainHeader__testServer {
  display: inline-block;
  color: $pharlap-contrast-AA;
  font-size: $font-size--1;
  letter-spacing: -0.025em;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: $logo-size / 5;
  margin-left: $logo-size / 4;
}
