@import "../../css/variables";

.vc-grid-wrapper {
  position: fixed;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.vc-grid-items {
  position: relative;
}

.vc-grid-item {
  position: absolute;
}

.vc-wrapper-hl-card {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: $spacer;

  &:hover {
    z-index: 1;
  }
}