@import "../../css/variables";
// @import "~bootstrap/scss/_functions";
// @import "~bootstrap/scss/_variables";

$universal: 62vmin;
$unit: $spacer / 3;

.timeline {
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}

.timeline__item {
  position: relative;
}

.timeline__time {
  z-index: 0;
  flex: 0 450px;
}

.timeline__exercises {
  z-index: 1;
  flex: 1;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-x: visible;

  &:not(.unscroll) {
    overflow-x: hidden;
  }
}
  

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}