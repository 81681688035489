// https://github.com/paranoida/sass-mediaqueries
@import "../../node_modules/sass-mediaqueries/media-queries";
@import "../css/variables";

.Catalog {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(#{$spacer * 12}, 1fr));
    grid-auto-rows: $spacer * 15;
    grid-gap: $spacer / 2;
    padding: $spacer $spacer / 2;
  }
  .loading-catalog{
    color: $hl-catalog-color-text;
    z-index: 1;
    background-color:  rgba($table-bg, 0.5);
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;

    &.hidden{
      animation-name: fadeOutOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.2s;
      opacity: 0;
      pointer-events: none;
    }
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
