@import "../../css/variables";

.hl-card--class {
  position: relative;
  height: #{"max(20vh, 15rem)"};
  // max-width: #{'max(20vh, 15rem)'};
  background-color: $secondary;
  color: #fff;

  .card-header {
    position: absolute;
    top: $spacer / 2;
    left: $spacer / 2;
    right: $spacer / 2;
    height: 0;
    display: flex;
    justify-content: space-between;
    background: transparent;
    border: 0;
    padding: 0;
    z-index: 2;
  }

  .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;

    a {
      color: currentColor;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;

      &:hover {
        color: currentColor;
        text-decoration: none;
      }
    }
  }

  .card-subtitle {
    font-size: $font-size--1;
    opacity: .8;
  }

  .hl-card__controls {
    a {
      color: currentColor;
    }
  }

  .hl-show-on-hover {
    display: none;
  }

  &:hover {
    .hl-show-on-hover {
      display: block;
    }
  }

  &.is-archived {
    opacity: 0.38;

    .hl-card__controls {
      justify-content: flex-end;
    }
  }

  .svg-inline--fa {
    cursor: pointer;
    display: block;
  }
}
