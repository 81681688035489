@import "../../css/variables";
// @import "~bootstrap/scss/_functions";
// @import "~bootstrap/scss/_variables";

$universal: 62vmin;
$unit: $spacer / 3;
$time-width: 30vw;

.timeline__itself {
  position: absolute;
  top: 0;
  left: $unit * 2;
  right: $unit;
  bottom: 0;

  opacity: 1;
  transform: none;
  transform-origin: left center;
  will-change: transform, opacity;
  transition: all $hl-transition-duration-medium;

  // &.unscroll {
  .unscroll & {
    opacity: 0;
    transform: translate3d($unit * -1, 0, 0) scale3d(0.98, 1, 1);
  }
}

//NAME POSITION
.timeline__names {
  z-index: 3;

  width: 100%;
  position: sticky;
  top: 0;

  .timeline__name {
    position: absolute;
    top: 0;
    background-color: $light;
    border-radius: $spacer;
    color: $dark;
    font-size: $font-size--2;
    line-height: $unit * 2;
    padding: 0 $unit;
    text-transform: uppercase;


    &:first-child {
      left: calc(25% - #{$unit / 2});
      transform: translate(-50%, 50%);
    }
    &:last-child {
      right: calc(25% - #{$unit / 2});
      transform: translate(50%, 50%);
    }
  }
}

.timeline__list {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  li {
    .middle-name {
      background: currentColor;
      height: $universal;
      margin: 0 auto;
      width: 1px;
      visibility: hidden;
    }
  }
}

//YEARS SETUP
.timeline__years {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .year {
    &:first-child {
      visibility: hidden;
    }

    > * {
      position: sticky;
      top: $unit;
      font-weight: bold;
      text-align: center;
      border-radius: $spacer;
      background-color: $light;
      color: $dark;
      padding: 0 $unit;
      text-transform: uppercase;
    }
  }

  li {
    &:first-child {
      padding-top: $unit;
      font-size: smaller;
    }
  }
}

.timeline__left {
  left: 0;
}

.timeline__right {
  right: 0;
}

// EPOCH setup
.timeline__epochs {
  z-index: 2;
  position: absolute;
  top: 0;
  width: calc(50% - #{$unit});
}

.epoch {
  padding-top: $unit * 2;

  &:last-child {
    padding-bottom: $unit * 2;
  }

  .epoch__inner {
    // cursor: pointer;
    height: 100%;
    padding: $unit * 2 0;
    border-radius: $unit;

    // box-shadow: 0 0.4rem 0.8rem rgba($dark, 0);
    // transition: color 0.19s ease, transform 0.19s ease, box-shadow 0.19s ease;
    // will-change: transform, box-shadow;

    // &:hover {
    //   border: 0;
    //   box-shadow: 0 0.4rem 0.8rem rgba($dark, 0.62);
    //   transform: translate3d(0, -0.4rem, 0);
    // }
  }

  .name {
    position: sticky;
    top: $unit * 4;
    line-height: 1.3;
    // font-size: $font-size-0;
    padding: $unit;
    text-align: center;
  }
}

// // EPOCH DETAIL
// .detail--epoch {
//   z-index: 5;

//   position: absolute;
//   top: $unit * 2;
//   height: 100%;
//   margin-top: $unit * -5;
//   width: calc(100% - #{$unit});
//   // padding: $unit * 2;
//   bottom: 0;

//   border-top-right-radius: $unit;
//   color: $dark;
//   will-change: transform;
//   transition: transform $hl-transition-duration-medium;

//   &:hover {
//     .detail__close {
//       display: block;
//     }
//   }

//   &.visible {
//     transform: none;

//     .detail__body {
//       opacity: 1;
//       transition: opacity $hl-transition-duration-medium
//         $hl-transition-duration-short;
//     }
//   }

//   &.hidden {
//     transform: translate3d(-100%, 0, 0);
//   }

//   .detail__inner {
//     position: sticky;
//     top: 0;
//   }

//   .detail__header {
//     color: rgba($dark, 0.62);
//     grid-template-columns: 1fr;

//     > * {
//       border-bottom-color: rgba($dark, 0.125);
//     }
//   }

//   .detail__body {
//     opacity: 0;
//     transition: opacity $hl-transition-duration-short;
//   }

//   .detail__close {
//     z-index: 3;
//     position: absolute;
//     top: $unit;
//     right: $unit;
//     left: unset;
//     display: none;

//     &:hover {
//       button {
//         background: $dark;
//         color: $light;
//       }
//     }

//     button {
//       background: transparent;
//       color: $dark;
//       border: 1px solid $dark;
//     }
//   }

//   .detail__name {
//     small {
//       opacity: 0.62;
//     }
//   }
// }
