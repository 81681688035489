@import "~modularscale-sass/stylesheets/modularscale";

// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
// https://getbootstrap.com/docs/4.5/getting-started/theming/

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$blue: #5352a6 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #a65252 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #52a666 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$pharlap: hsl(2, 21%, 56%);
$pharlap-contrast-AA: hsl(2, 21%, 49%);
$mahogany: hsla(2, 63%, 49%);

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: $blue !default;
$secondary: #7099a2;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $mahogany;
$light: #faf5f5; // #fce4e3
$dark: #1c1312;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.6rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1490px,
  xxxl: 1920px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px,
  xxxl: 1600px,
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: "Lato", $font-family-system;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$line-height-base: 1.6;
$line-height-sm: 1.3;

// Modular scale
// https://www.modularscale.com/?1&rem&1.25

$modularscale: (
  base: $font-size-base,
  ratio: 1.25,
);

$font-size-4: ms(4);
$font-size-3: ms(3);
$font-size-2: ms(2);
$font-size-1: ms(1);
$font-size-0: ms(0);
$font-size--1: ms(-1);
$font-size--2: ms(-2);
$font-size--3: ms(-3);

$h1-font-size: $font-size-4;
$h2-font-size: $font-size-3;
$h3-font-size: $font-size-2;
$h4-font-size: $font-size-1;
$h5-font-size: $font-size-0;
$h6-font-size: $font-size--1;

$display1-size: ms(8);
$display2-size: ms(7);
$display3-size: ms(6);
$display4-size: ms(5);

$font-weight-bold: 700 !default;

$headings-font-weight: $font-weight-bold;

$hr-margin-y: $spacer / 2;

// Breadcrumbs
$breadcrumb-font-size: $font-size--1;
$breadcrumb-bg: transparent;
$breadcrumb-color: $secondary;
$breadcrumb-active-color: $dark;
$breadcrumb-divider-color: $breadcrumb-color;
$breadcrumb-item-padding: 0.62ch;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: $spacer;
// $breadcrumb-border-radius: 0;

// Buttons
// $btn-border-radius:           $border-radius !default;
// $btn-border-radius-lg: $unit * 2;
// $btn-border-radius-sm:        $border-radius-sm !default;

// Modal
$modal-inner-padding: $spacer * 1.5;

// Navs
$nav-link-padding-y: $spacer / 6;
$nav-link-padding-x: $spacer / 3;

// Navbar

$navbar-padding-y: $spacer / 1.5;
$navbar-padding-x: 0;

$navbar-dark-color: rgba($dark, 0.5);
$navbar-dark-hover-color: rgba($dark, 0.75);
$navbar-dark-active-color: $dark;
$navbar-dark-disabled-color: rgba($dark, 0.25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: $dark;

$navbar-light-color: rgba($light, 0.5);
$navbar-light-hover-color: rgba($light, 0.7);
$navbar-light-active-color: $light;
$navbar-light-disabled-color: rgba($light, 0.3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($light, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-bg: $light;
$table-accent-bg: $white;

// HISTORYLAB specific
$hl-transition-duration-short: 0.2s;
$hl-transition-duration-medium: 0.3s;
$hl-transition-duration-long: 0.6s;

$hl-catalog-color-text: $pharlap-contrast-AA;
$hl-catalog-color-hover: $dark;
$hl-catalog-color-bg: $light;
$hl-catalog-tag-bgColor: $dark;
$hl-catalog-tag-color: $light;
