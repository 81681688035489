@import "../../css/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$universal: 62vmin;
$unit: 1vmin;

.TimelineExerciseDetail {
  z-index: 3;

  position: absolute;
  top: $unit * 2;
  right: 0;
  bottom: 0;
  width: calc(100% - #{$unit * 2});
  display: flex;

  color: $light;
  background: $dark;
  border: 1px solid $dark;
  border-top-left-radius: $unit;
  will-change: transform;
  transition: transform $hl-transition-duration-medium;

  &:hover {
    .detail__close {
      display: block;
    }
  }

  &.is--hidden {
    transform: translate3d(100%, 0, 0);

    .detail__body {
      opacity: 0;
    }
  }

  .detail__body {
    flex: 1;
    position: relative;
    color: $light;
    min-width: 38vw;
    width: calc(100% - 450px);
    height: 100%;
    transition: opacity $hl-transition-duration-medium
      $hl-transition-duration-short;
  }
}

.detail__close {
  z-index: 3;
  position: absolute;
  top: $unit;
  left: $unit;
  display: none;

  &:hover {
    button {
      background: $light;
      color: $dark;
    }
  }

  button {
    appearance: none;
    background: $dark;
    border: 1px solid $light;
    border-radius: $unit * 3;
    color: $light;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: $unit * 3;
    height: $unit * 3;
  }
}

.detail__body {
  position: relative;
  padding: $unit * 3 $unit * 4 $unit * 4;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;

  transition: opacity $hl-transition-duration-short;

  > :not(:last-child) {
    margin-bottom: $spacer;
  }
}

.detail__more {
  color: rgba($light, 0.62);
  font-size: $font-size--1;
  line-height: $headings-line-height;
  margin-top: $spacer / 3 * -1;

  a {
    color: currentColor;

    span {
      display: inline-block;
      transition: transform $hl-transition-duration-short;
    }

    &:hover {
      span {
        transform: translate3d(25%, 0, 0);
      }
    }
  }
}

.detail__header {
  display: grid;
  grid-template-columns: minmax(15ch, auto) 1fr minmax(13ch, auto);
  grid-template-areas:
    "difficulty epochs years"
    "time keywords guide";
  font-size: $font-size--1;
  line-height: $headings-line-height;
  color: rgba($light, 0.62);

  > * {
    border-bottom: 1px solid rgba($light, 0.125);
    display: flex;
    align-items: flex-end;
    padding: $spacer $spacer / 4 $spacer / 4;
    position: relative;

    &::before {
      content: attr(data-caption);
      position: absolute;
      top: $spacer / 4;
      left: $spacer / 4.5;
      font-size: $font-size--3;
      letter-spacing: 0.05em;
      opacity: 0.62;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  a {
    color: $mahogany;
  }
}

// .detail__author,
// .detail__difficulty,
// .detail__epochs,
// .detail__years,
// .detail__keywords {}

.detail__difficulty,
.detail__epochs,
.detail__keywords,
.detail__time {
  border-right: 1px solid rgba($light, 0.125);
  // border-right: 1px solid rgba($light, 1);
}

.detail__guide {
  grid-area: guide;

  :not(:first-child) {
    margin-left: 0.75ch;
  }
}
.detail__time {
  grid-area: time;
}
.detail__difficulty {
  grid-area: difficulty;
}
.detail__epochs {
  grid-area: epochs;

  .badge--history:not(:last-child)::after {
    content: ", ";
  }

  // span {
  //   display: inline-block;

  //   &::first-letter {
  //     text-transform: uppercase;
  //   }
  // }
}
.detail__years {
  grid-area: years;
}
.detail__keywords {
  grid-area: keywords;

  .keyword:not(:first-child) {
    margin-left: 0.62ch;
  }
}

.detail__name {
  grid-area: name;
  padding-top: $spacer;

  small {
    opacity: 0.62;
  }
}

.detail__description {
  grid-area: description;
  flex: 1;
}

.detail__buttons {
  grid-area: buttons;
  position: sticky;
  bottom: 0;
}

.detail__image {
  order: 1;
  flex: 0 450px;
  transition: flex $hl-transition-duration-long cubic-bezier(0.25, 1, 0.5, 1);

  &:hover {
    flex: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .TimelineExerciseDetail.is--hidden & {
    flex: 0 1px;
    transition: flex $hl-transition-duration-medium $hl-transition-duration-long
    cubic-bezier(0.25, 1, 0.5, 1);
  }
}

.TimelineExerciseDetail--gallery {
  width: calc(100% - #{$unit * 2} - 450px);
}
