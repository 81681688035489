/* DO NOT USE THIS FILE. Deprecated! */

body {
  background-color: var(--light);
  color: var(--dark);
  display: flex;
  flex-flow: column;
  font-family: "Lato", -apple-system, Helvetica, Arial, sans-serif;
  line-height: 1.4rem;
  min-height: 100vh;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  flex: 1;
  min-height: 62vh;
}

/*in process*/

.in-process {
  display: none;
}

.google-login svg {
  vertical-align: middle;
  margin-top: -2px;
}

.cookie-consent {
  z-index: 9999999;
  position: fixed;
  right: 1.6rem;
  bottom: 1.6rem;
  min-width: calc(320px - 3.2rem);
  max-width: calc(100% - 3.2rem);
  width: 32rem;
  height: auto;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: left;
  background-color: #1b1313;
  color: #fdf2f1;
  box-shadow: 0 0 1.6rem rgba(253, 242, 241, 0.12);
  border-radius: 1.6rem;
  padding: 0.8rem 1.6rem;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@supports (-webkit-backdrop-filter: blur(10px)) {
  .cookie-consent {
    -webkit-backdrop-filter: blur(1.6rem);
    background-color: rgba(27, 19, 19, 0.9);
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) {
  .cookie-consent {
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
  }
}
.cookie-consent .cookie-consent__text {
  flex: 1;
}
.cookie-consent p {
  margin-bottom: 0;
}
.cookie-consent .btn {
  display: block;
  margin: 0.4rem auto 0;
  text-transform: uppercase;
  font-size: 0.64rem;
  line-height: 1.2rem;
  background-color: #1b1313;
  color: #fdf2f1;
  border-color: #fdf2f1;
  border-radius: 1.6rem;
  box-shadow: none;
  cursor: pointer;
}
.cookie-consent .btn:hover {
  background-color: #fdf2f1;
  color: #1b1313;
}
@media only screen and (min-width: 768px) {
  .cookie-consent {
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
    padding: 1.6rem;
  }
  .cookie-consent .btn {
    margin: 0 auto;
  }
}

.custom-control-label {
  cursor: pointer;
}

:disabled + .custom-control-label {
  cursor: default;
}