@import "./css/variables";

.footer {
  background: var(--dark);
  color: var(--light);
  height: 16rem;

  a {
    color: currentColor;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: color 0.1s cubic-bezier(0.62, 0.05, 0.38, 1),
      border-bottom-color 0.1s cubic-bezier(0.62, 0.05, 0.38, 1);

    &:hover {
      border-bottom: 1px solid currentColor;
      text-decoration: none;
    }
  }
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  ul {
    &:nth-child(1) {
      border-bottom: 1px solid rgba($light, 0.125);
      padding-bottom: $spacer / 2;
      margin-bottom: $spacer / 2;

      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: $spacer;
        }
      }
    }

    &:nth-child(2) {
      color: rgba($light, 0.62);
      font-size: $font-size--1;
    }
  }
}

.footer-logo svg {
  fill: currentColor;
  height: $spacer;
}
