@import "./css/variables";

.layout__header {
  justify-content: space-between;

  header {
    margin-bottom: $spacer;
  }

  .subHeader {
    flex: initial;
    width: auto;
  }
}